<template></template>

<script>
export default {
  created() {
    localStorage.removeItem("user");
    this.$router.push("/login");
  },
};
</script>

<style>
</style>